import React from 'react'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import PageHeader from '../../components/pageHeader'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
const KunskapsprovKorprov = (props) => (
  <Layout>
    <Seo title='Kunskapsprov och Körprov' />
    <PageHeader text='Kunskapsprov och Körprov' />
    <div className='container'>
      <div className='row'>

        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12'>
          <div>
          <Img fluid={props.data.imageOne.childImageSharp.fluid} alt='trafikverket' className='rounded' />
          <br></br>
            <p>När du börjar bli klar med sin körkortsutbildning, bokas kunskapsprov och körprov vid samma tillfälle. Proven utförs med 2-3 veckors mellanrum. Kunskapsprovet är den delen du gör först och kräver att riskutbildningarna är genomförda innan. Körprovet är den andra delen och kräver fr.o.m. 1/3 2020 att du har ett godkänt kunskapsprov innan du får genomföra det.</p>
            <p>Bokar du prov som privatist kan du välja hur du vill boka dina prov, exempelvis bara boka kunskapsprovet eller både kunskapsprovet och körprovet </p>
          </div>
        </div>
        <div className='container'>
          <div className='row mt-5 justify-content-between'>
            <div className='col-12 col-sm-12 col-md-12 col-lg-7 col-xl-6 mb-2 '>
              <div>
                <h3>Kundskapsprov</h3>
                <p>
                  Kunskapsprovet genomförs på datorer hos Trafikverket och fungerar på samma sätt som körkortsfrågorna på elevcentralen.
                  Provet består av 70 frågor varav 5 frågor är för utbildningssyfte och räknas inte med i resultatet.
                  Kravet för att bli godkänd är 52 rätt och du har 50 minuter på dig att genomföra provet.
                  Du kan ansöka om dispens för att få anpassat kunskapsprov. Läs mer  <a href='https://www.trafikverket.se/korkort/korkortsprov/' target='blank'>här.</a>
                </p>
                <p>I samband med kunskapsprov ska du vara på plats ca 30 minuter innan utsatt tid för att legitimera dig med giltig legitimation och ta foto. </p>
                <p>Fr.o.m. 1/10 2022 gäller godkänt kunskapsprov 4 månader.
                  Läs mer <a href='https://www.transportstyrelsen.se/sv/Nyhetsarkiv/2020/transportstyrelsen-forlanger-giltighetstiden-for-forarprov-behorighet-b/' target='blank'>här </a>
                  om de nya provreglerna.
                </p>
              </div>
            </div>

            <div className='col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 p-0 mt-lg-5 mt-xl-5 mt-sm-1 text-center'>
              <div className='embed-responsive embed-responsive-16by9'>
                <iframe title='Kunskapsprov' className='embed-responsive-item' src='https://www.youtube.com/embed/m80w2gLX75Q' />
              </div>
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='row mt-5 justify-content-between'>
            {/* <div className='col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 p-0  mt-lg-5 mt-xl-5 mt-sm-1 text-center videoShowBigScreen'>
              <div className='embed-responsive embed-responsive-16by9'>
                <iframe title='korprov' className='embed-responsive-item' src='https://www.youtube.com/embed/81y3gPXAC6U' />
              </div>
            </div> */}
            <div className='col-12 col-sm-12 col-md-12 col-lg-7 col-xl-6 mb-2 '>
              <div>
                <h3>Körprov</h3>
                <p>Din lärare följer med dig till Trafikverket, där du får träffa en förarprövare som du kör upp för. Innan provet startar ska du legitimera dig med giltig legitimation och skriva under på att du inte har körkort i ett annat EES-land.</p>
                <p>Provet börjar med att du gör en säkerhetskontroll på bilen. När ni sätter er i bilen går förarprövaren igenom det protokoll som förs och vilka bedömningspunkter som ligger till grund för beslutet. Under körningen kryssar förarprövaren i vilka moment du gör under körningen. </p>
                <p>Dirigeringen under uppkörningen kommer du att få på olika sätt, vänster, höger eller följa en anvisning. Provet tar ca 45 minuter.</p>
                <p>Förarprövaren bedömer helheten i din körning och ger dig resultatet när ni återvänt till Trafikverket.</p>
                <p>Ditt körkort kommer på posten inom 5 arbetsdagar i ett rekommenderat brev.
                  Du har en prövotid på 2 år när du tagit körkort, det innebär att om du gör någon trafikförseelse som föranleder körkortsindragning måste du ta om ditt hela ditt körkort.
                </p>
              </div>
            </div>

            <div className='col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 pb-5 px-0 videoHideBigScreen'>
              <div className='embed-responsive embed-responsive-16by9'>
                <iframe title='korprov' className='embed-responsive-item' src='https://www.youtube.com/embed/znQ4ujIKDPM?start=5' />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default KunskapsprovKorprov

export const pageQuerys = graphql`
  query {
    imageOne: file(relativePath: { eq: "information/trafikverket.png" }) {
      childImageSharp {
        fluid(maxWidth: 292, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
} 
`     